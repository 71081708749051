<template>
    <div class="result_page">
        <div class="result_title" style="padding-top: 20px">
            <SmallTitle tit="计算结果筛选" />
        </div>
        <div class="search_box">
            <div class="unit">
                <h1>E</h1>
                <div class="li">
                    <h6>店均推荐数</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.avgRecommendsNum.minValue" :placeholder="defaultData?.avgRecommendsNum?.minValue?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.avgRecommendsNum.maxValue" :placeholder="defaultData?.avgRecommendsNum?.maxValue?.toString() || ''"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>过往12期上榜数</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.avgRecommendsNum.periodMin" :placeholder="defaultData?.avgRecommendsNum?.periodMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.avgRecommendsNum.periodMax" :placeholder="defaultData?.avgRecommendsNum?.periodMax?.toString() || ''"></el-input>
                    </div>
                </div>
            </div>
            <div class="unit">
                <h1>G</h1>
                <div class="li">
                    <h6>推荐数增长率</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.recommendsNumInc.minValue" :placeholder="defaultData?.recommendsNumInc?.minValue?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.recommendsNumInc.maxValue" :placeholder="defaultData?.recommendsNumInc?.maxValue?.toString() || ''"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>过往12期上榜数</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.recommendsNumInc.periodMin" :placeholder="defaultData?.recommendsNumInc?.periodMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.recommendsNumInc.periodMax" :placeholder="defaultData?.recommendsNumInc?.periodMax?.toString() || ''"></el-input>
                    </div>
                </div>
            </div>
            <div class="unit">
                <h1>P</h1>
                <div class="li">
                    <h6>菜品在其他菜系门店数</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.recommendsNum.minValue" :placeholder="defaultData?.recommendsNum?.minValue?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.recommendsNum.maxValue" :placeholder="defaultData?.recommendsNum?.maxValue?.toString() || ''"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>过往12期上榜数</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.recommendsNum.periodMin" :placeholder="defaultData?.recommendsNum?.periodMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.recommendsNum.periodMax" :placeholder="defaultData?.recommendsNum?.periodMax?.toString() || ''"></el-input>
                    </div>
                </div>
            </div>
            <div class="unit">
                <h1>M</h1>
                <div class="li">
                    <h6>过往12期上榜数</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.period.periodMin" :placeholder="defaultData?.period?.periodMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.period.periodMax" :placeholder="defaultData?.period?.periodMax?.toString() || ''"></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn_box">
            <el-button type="info" @click="reset">重置</el-button>
            <el-button type="primary" @click="search">搜索</el-button>
        </div>
        <div class="result_title search_title">
            <SmallTitle tit="计算结果" />
            <div class="search_input">
                <el-input placeholder="菜品名称" clearable style="width: 300px" v-model="form.keyword" @keydown.enter="search">
                    <template #append>
                        <el-button :icon="Search" @click.stop="search" />
                    </template>
                </el-input>
            </div>
        </div>
        <div class="result_container">
            <div class="tab">
                <li v-for="item in tabs" :key="item.path" :class="{ active: $route.path == item.path }" @click="switchTab(item)">{{ item.name }}</li>
            </div>
            <div class="con">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script setup>
import SmallTitle from "@/components/common/SmallTitle.vue";
import { useRoute, useRouter } from "vue-router";
import useResultStore from "@/store/egpm/useResultStore";
import { reactive, ref, onBeforeMount } from "vue";
import { cloneDeep } from "loadsh";
import { apiEgpmTaskResultFilters } from "@/request/egpmApi";
import { Search } from "@element-plus/icons-vue";
// import useDict from "./useDict";

const resultStore = useResultStore();
const route = useRoute();
const router = useRouter();
const tabs = [
    { name: "EGPM单期图表", path: "/egpm/details/result/single" },
    { name: "EGPM菜品清单", path: "/egpm/details/result/list" },
];
const form = reactive({
    keyword: "",
    // 店均推荐数:E
    avgRecommendsNum: {
        flag: "E",
        minValue: null,
        maxValue: null,
        periodMin: null,
        periodMax: null,
    },
    // 推荐总数增长率:G
    recommendsNumInc: {
        flag: "G",
        minValue: null,
        maxValue: null,
        periodMin: null,
        periodMax: null,
    },
    // 菜品在其他菜系门店推荐数(推荐总数):P
    recommendsNum: {
        flag: "P",
        minValue: null,
        maxValue: null,
        periodMin: null,
        periodMax: null,
    },
    // 过往12期:M
    period: {
        flag: "M",
        periodMin: null,
        periodMax: null,
    },
});
const defaultData = ref({}); // 默认值

// 字典
// const { dicts } = useDict({ form });

// 搜索
function search() {
    resultStore.handleSearch(cloneDeep(form));
}

// 获取初始化值
async function getDefault() {
    const { data } = await apiEgpmTaskResultFilters({ taskId: route.query.id });
    defaultData.value = data || {};
}

// 重置
function reset() {
    form.keyword = "";
    form.avgRecommendsNum.minValue = null;
    form.avgRecommendsNum.maxValue = null;
    form.avgRecommendsNum.periodMin = null;
    form.avgRecommendsNum.periodMax = null;
    form.recommendsNumInc.minValue = null;
    form.recommendsNumInc.maxValue = null;
    form.recommendsNumInc.periodMin = null;
    form.recommendsNumInc.periodMax = null;
    form.recommendsNum.minValue = null;
    form.recommendsNum.maxValue = null;
    form.recommendsNum.periodMin = null;
    form.recommendsNum.periodMax = null;
    form.period.periodMin = null;
    form.period.periodMax = null;
    search();
}

// 切换
function switchTab(item) {
    router.replace({
        path: item.path,
        query: {
            reportId: route.query.reportId,
            id: route.query.id,
        },
    });
}

onBeforeMount(() => {
    resultStore.setSreen({});
    getDefault();
});
</script>

<style lang="scss" scoped>
.result_page {
    .search_box {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        padding-top: 20px;
        .unit {
            display: flex;
            flex-wrap: wrap;
            border: 1px dashed #ccc;
            padding: 15px;
            border-radius: 4px;
            margin-right: 15px;
            margin-bottom: 15px;
            position: relative;
            h1 {
                position: absolute;
                left: 15px;
                top: -12px;
                display: block;
                line-height: 24px;
                background-color: var(--primary-color);
                color: #fff;
                font-weight: bold;
                text-align: center;
                padding: 0 15px;
                border-radius: 2px;
            }
            .li {
                margin-right: 20px;
                margin-bottom: 10px;

                h6 {
                    font-weight: normal;
                    color: #818a9b;
                    padding-bottom: 6px;
                    font-size: 14px;
                }

                .el-select {
                    width: 200px;
                }

                :deep(.el-input__inner) {
                    border-radius: 7px;
                }

                .int_box {
                    display: flex;
                    align-items: center;

                    .el-input {
                        width: 98px;
                    }
                }
            }
        }
    }
    .search_form {
        padding: 20px;
        padding-bottom: 0;
        .row {
            display: flex;
            margin-bottom: 14px;
            flex-wrap: wrap;
            .item {
                margin-right: 12px;
                margin-bottom: 12px;
                h6 {
                    font-weight: normal;
                    color: #818a9b;
                    padding-bottom: 6px;
                    font-size: 14px;
                }
                .el-input,
                .el-select {
                    width: 200px;
                }
            }
        }
    }
    .btn_box {
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }
    .search_title {
        position: relative;
        .search_input {
            position: absolute;
            left: 165px;
            bottom: -62px;
            z-index: 2;
            display: flex;
            align-items: center;
        }
    }
    .result_container {
        border-radius: 4px;
        margin-top: 15px;
        position: relative;
        padding-left: 150px;
        .tab {
            width: 150px;
            position: absolute;
            top: 0;
            left: 0;
            li {
                text-align: center;
                line-height: 50px;
                cursor: pointer;
                border-radius: 4px;
                padding: 0 10px;
                background-color: #f2f2f2;
                user-select: none;
                &.active {
                    background-color: var(--primary-color);
                    color: #fff;
                }
            }
        }
        .con {
            border: 1px solid #f2f2f2;
            border-radius: 4px;
            padding: 15px;
            min-height: 500px;
        }
    }
}
</style>
